<template>
  <g>
    <svg:style>
      .cls-10, .cls-11, .cls-12, .cls-13, .cls-4, .cls-5, .cls-6, .cls-7, .cls-8, .cls-9 {
        stroke: #aeaeae;
        stroke-miterlimit: 10;
        stroke-width: 0.25px;
      }

      .cls-4 {
        fill: url(#inox-gradient-3);
      }

      .cls-5 {
        fill: url(#glass-pattern);
      }

      .cls-6 {
        fill: url(#inox-gradient-4);
      }
      .cls-7 {
        fill: url(#glass-pattern);
      }
      .cls-8 {
        fill: url(#inox-gradient-3);
      }
      .cls-9 {
        fill: url(#glass-pattern);
      }
      .cls-10 {
        fill: url(#inox-gradient-4);
      }
      .cls-11 {
        fill: url(#glass-pattern);
      }
      .cls-12 {
        stroke-linecap: square;
        fill: url(#glass-pattern);
      }
      .cls-13 {
        fill: url(#inox-gradient-5);
      }
    </svg:style>
    <linearGradient id="inox-gradient"
      :x1="doorLeftWidth1 + 69.91"
      :y1="doorTopHeight1 + 0.12"
      :x2="doorLeftWidth1 + 69.91"
      :y2="doorTopHeight1 + 293.21"
      gradientUnits="userSpaceOnUse">
      <stop offset="0.25" stop-color="#f9f9f9"/>
      <stop offset="0.67" stop-color="#e8e8e8"/>
      <stop offset="0.79" stop-color="#e2e2e2"/>
    </linearGradient>

    <linearGradient id="handle-gradient"
      :x1="doorLeftWidth1 + 11.16"
      :y1="doorTopHeight1 + 148.47"
      :x2="doorLeftWidth1 + 16.75"
      :y2="doorTopHeight1 + 148.47"
      gradientUnits="userSpaceOnUse">
    <stop offset="0.01" stop-color="#babbbc"/>
    <stop offset="0.29" stop-color="#989a9e"/>
    <stop offset="0.65" stop-color="#bdbdc1"/>
    <stop offset="0.87" stop-color="#9d9fa2"/>
    <stop offset="1" stop-color="#a6a8aa"/>
    <stop offset="1" stop-color="#c2c5c9"/>
    </linearGradient>
    <linearGradient id="inox-gradient"
      :x1="doorLeftWidth1 + 42.31"
      :y1="doorTopHeight1 + 133.19"
      :x2="doorLeftWidth1 + 63.24"
      :y2="doorTopHeight1 + 133.19"
      xlink:href="#handle-gradient"/>
    <linearGradient id="glass-gradient"
      :x1="doorLeftWidth1 + 52.77"
      :y1="doorTopHeight1 + 160.41"
      :x2="doorLeftWidth1 + 52.77"
      :y2="doorTopHeight1 + 105.98"
      gradientUnits="userSpaceOnUse">
    <stop offset="0" stop-color="#f4f4f4"/>
    <stop offset="0.5" stop-color="#dee8e8"/>
    <stop offset="1" stop-color="#d1cece"/>
    </linearGradient>
    <linearGradient id="inox-gradient-2" data-name="inox-gradient" x1="77.17" y1="133.19" x2="98.1" y2="133.19" xlink:href="#handle-gradient"/>
    <linearGradient id="glass-gradient-2"
      data-name="glass-gradient"
      :x1="doorLeftWidth1 + 87.63"
      :y1="doorTopHeight1 + 160.41"
      :x2="doorLeftWidth1 + 87.63"
      :y2="doorTopHeight1 + 105.98"
      xlink:href="#glass-gradient"/>
    <linearGradient id="inox-gradient-3"
      data-name="inox-gradient"
      :x1="doorLeftWidth1 + 42.31"
      :y1="doorTopHeight1 + 209.69"
      :x2="doorLeftWidth1 + 63.24"
      :y2="doorTopHeight1 + 209.69"
      xlink:href="#handle-gradient"/>
    <linearGradient id="glass-gradient-3"
      data-name="glass-gradient"
      :x1="doorLeftWidth1 + 52.77"
      :y1="doorTopHeight1 + 236.91"
      :x2="doorLeftWidth1 + 52.77"
      :y2="doorTopHeight1 + 182.48"
      xlink:href="#glass-gradient"/>
    <linearGradient id="inox-gradient-4"
      data-name="inox-gradient"
      :x1="doorLeftWidth1 + 77.17"
      :y1="doorTopHeight1 + 209.69"
      :x2="doorLeftWidth1 + 98.1"
      :y2="doorTopHeight1 + 209.69"
      xlink:href="#handle-gradient"/>
    <linearGradient id="glass-gradient-4"
      data-name="glass-gradient"
      :x1="doorLeftWidth1 + 87.63"
      :y1="doorTopHeight1 + 236.91"
      :x2="doorLeftWidth1 + 87.63"
      :y2="doorTopHeight1 + 182.48"
      xlink:href="#glass-gradient"/>
    <linearGradient id="glass-gradient-5"
      data-name="glass-gradient"
      :x1="doorLeftWidth1 + 1176.53"
      :y1="doorTopHeight1 + 6589.4"
      :x2="doorLeftWidth1 + 1176.53"
      :y2="doorTopHeight1 + 6613.42"
      xlink:href="#glass-gradient"/>
    <linearGradient id="inox-gradient-5"
      data-name="inox-gradient"
      :x1="doorLeftWidth1 + 1176.53"
      :y1="doorTopHeight1 + 6585.21"
      :x2="doorLeftWidth1 + 1176.53"
      :y2="doorTopHeight1 + 6618.14"
      xlink:href="#handle-gradient"/>


    <filter id="inset-shadow">
      <feOffset dx="0" dy="0"/>
      <feGaussianBlur stdDeviation="1"  result="offset-blur"/>
      <feComposite operator="out" in="SourceGraphic" in2="offset-blur" result="inverse"/>
      <feFlood flood-color="black" flood-opacity="1" result="color"/>
      <feComposite operator="in" in="color" in2="inverse" result="shadow"/>
      <feComponentTransfer in="shadow" result="shadow">
        <feFuncA type="linear" slope="1.25"/>
      </feComponentTransfer>
      <feComposite operator="over" in="shadow" in2="SourceGraphic"/>
    </filter>
    <g id="V19">
      <polyline id="inox"
                v-if="showInox"
                class="cls-4"
                :points="`${doorLeftWidth1 + 42.31} ${doorTopHeight1 + 101.79} ${doorLeftWidth1 + 42.31} ${doorTopHeight1 + 164.6} ${doorLeftWidth1 + 63.24} ${doorTopHeight1 + 164.6} ${doorLeftWidth1 + 63.24} ${doorTopHeight1 + 101.79} ${doorLeftWidth1 + 42.31} ${doorTopHeight1 + 101.79}`"/>
      <polyline id="glass"
                class="cls-5"
                :points="`${doorLeftWidth1 + 46.49} ${doorTopHeight1 + 105.98} ${doorLeftWidth1 + 46.49} ${doorTopHeight1 + 160.41} ${doorLeftWidth1 + 59.05} ${doorTopHeight1 + 160.41} ${doorLeftWidth1 + 59.05} ${doorTopHeight1 + 105.98} ${doorLeftWidth1 + 46.49} ${doorTopHeight1 + 105.98}`"/>
      <polyline id="inox-2"
                v-if="showInox"
                data-name="inox"
                class="cls-6"
                :points="`${doorLeftWidth1 + 77.17} ${doorTopHeight1 + 101.79} ${doorLeftWidth1 + 77.17} ${doorTopHeight1 + 164.6} ${doorLeftWidth1 + 98.1} ${doorTopHeight1 + 164.6} ${doorLeftWidth1 + 98.1} ${doorTopHeight1 + 101.79} ${doorLeftWidth1 + 77.17} ${doorTopHeight1 + 101.79}`"/>
      <polyline id="glass-2"
                data-name="glass"
                class="cls-7"
                :points="`${doorLeftWidth1 + 81.35} ${doorTopHeight1 + 105.98} ${doorLeftWidth1 + 81.35} ${doorTopHeight1 + 160.41} ${doorLeftWidth1 + 93.91} ${doorTopHeight1 + 160.41} ${doorLeftWidth1 + 93.91} ${doorTopHeight1 + 105.98} ${doorLeftWidth1 + 81.35} ${doorTopHeight1 + 105.98}`"/>
      <polyline id="inox-3"
                v-if="showInox"
                data-name="inox"
                class="cls-8"
                :points="`${doorLeftWidth1 + 42.31} ${doorTopHeight1 + 178.29} ${doorLeftWidth1 + 42.31} ${doorTopHeight1 + 241.09} ${doorLeftWidth1 + 63.24} ${doorTopHeight1 + 241.09} ${doorLeftWidth1 + 63.24} ${doorTopHeight1 + 178.29} ${doorLeftWidth1 + 42.31} ${doorTopHeight1 + 178.29}`"/>
      <polyline id="glass-3"
                data-name="glass"
                class="cls-9"
                :points="`${doorLeftWidth1 + 46.49} ${doorTopHeight1 + 182.48} ${doorLeftWidth1 + 46.49} ${doorTopHeight1 + 236.91} ${doorLeftWidth1 + 59.05} ${doorTopHeight1 + 236.91} ${doorLeftWidth1 + 59.05} ${doorTopHeight1 + 182.48} ${doorLeftWidth1 + 46.49} ${doorTopHeight1 + 182.48}`"/>
      <polyline id="inox-4"
                v-if="showInox"
                data-name="inox"
                class="cls-10"
                :points="`${doorLeftWidth1 + 77.17} ${doorTopHeight1 + 178.29} ${doorLeftWidth1 + 77.17} ${doorTopHeight1 + 241.09} ${doorLeftWidth1 + 98.1} ${doorTopHeight1 + 241.09} ${doorLeftWidth1 + 98.1} ${doorTopHeight1 + 178.29} ${doorLeftWidth1 + 77.17} ${doorTopHeight1 + 178.29}`"/>
      <polyline id="glass-4"
                data-name="glass"
                class="cls-11"
                :points="`${doorLeftWidth1 + 81.35} ${doorTopHeight1 + 182.48} ${doorLeftWidth1 + 81.35} ${doorTopHeight1 + 236.91} ${doorLeftWidth1 + 93.91} ${doorTopHeight1 + 236.91} ${doorLeftWidth1 + 93.91} ${doorTopHeight1 + 182.48} ${doorLeftWidth1 + 81.35} ${doorTopHeight1 + 182.48}`"/>
      <path
        id="glass-5"
        data-name="glass"
        class="cls-12"
        d="M1176.53,6589.4a23.87,23.87,0,0,0-23.87,23.87s0,0.1,0,.15h17.43v-0.06a6.44,6.44,0,0,1,12.89,0v0.06h17.43s0-.1,0-0.15A23.87,23.87,0,0,0,1176.53,6589.4Z"
        :transform="`translate(${doorLeftWidth1 - 1106.45} ${doorTopHeight1 - 6530.2})`"/>
      <path
        class="cls-13"
        v-if="showInox"
        d="M1176.53,6585.21a28.06,28.06,0,0,0-28.06,28.06s0,0.1,0,4.87h21.62c0-4.75,0-4.77,0-4.78a6.44,6.44,0,0,1,12.89,0s0,0,0,4.78h21.62c0-4.77,0-4.82,0-4.87A28.06,28.06,0,0,0,1176.53,6585.21Zm23.87,28.21h-13.33v-0.08a10.54,10.54,0,1,0-21.07,0v0.08h-13.33s0-.1,0-0.15a23.87,23.87,0,1,1,47.75,0S1200.4,6613.37,1200.4,6613.42Z"
        :transform="`translate(${doorLeftWidth1 - 1106.45} ${doorTopHeight1 - 6530.2})`"/>
    </g>
  </g>
</template>

<script>
export default {
  props: [
    'doorWidth',
    'doorHeight',
    'doorLeftWidth',
    'doorTopHeight',
    'doorGlassImage',
    'showInox',
  ],
  computed: {
    doorLeftWidth1() {
      return this.doorLeftWidth + this.doorWidth/2 - 70
    },
    doorTopHeight1() {
      return this.doorTopHeight + this.doorHeight/2 - 148
    },
  }
}
</script>
